<template>
  <div>
    <top-line-city show-city title="Тарифы" image="/BG.png" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row">
            <div class="col-12 col-md-9">
              <div class="content content-white">
                <table class="w-100 table table-hover">
                  <thead>
                    <tr>
                      <th>Модель</th>
                      <th v-for="rate in getRates" :key="rate.name">
                        {{ rate.name }} (<template
                          v-if="rate.start_day && rate.end_day"
                          >{{ rate.start_day }} -
                          {{ rate.end_day }} сут.</template
                        >
                        <template v-else>от {{ rate.start_day }} сут.</template
                        >)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="car in getCarsSortedByRate" :key="car.id">
                      <td>{{ car.name }}</td>
                      <td v-for="rate in getRates" :key="rate.name">
                        {{
                          car.rates.find(
                            (carRate) => carRate.name === rate.name
                          )?.summ
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12 col-md-3 pt-4 pt-md-0">
              <info-sidebar :info="getMainStock" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopLineCity from "@/components/pages/TopLineCity";
import InfoSidebar from "@/components/pages/InfoSidebar";
import { mapGetters } from "vuex";
import { find, forEach, sortBy } from "lodash";
export default {
  name: "TariffPage",
  components: { InfoSidebar, TopLineCity },
  head: {
    title: function () {
      return {
        inner: this.$route.meta?.title || "Прокат авто",
      };
    },
  },
  computed: {
    ...mapGetters("content", ["getMainStock"]),
    ...mapGetters("car", ["getCarsSortedByRate"]),
    getRates() {
      const rates = [];
      forEach(this.getCarsSortedByRate, (car) => {
        forEach(car.rates, (rate) => {
          if (!find(rates, (rateItem) => rateItem.name === rate.name)) {
            rates.push(rate);
          }
        });
      });
      return sortBy(rates, "name");
    },
  },
};
</script>

<style scoped lang="scss">
table {
  @include media(768) {
    * {
      font-size: em(14);
    }
  }
}
</style>
